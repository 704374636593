import { Store } from 'vuex'
import { initialiseStores } from './store-accessor'

const initializer = (store: Store<any>) => initialiseStores(store)

export const plugins = [initializer]

// export const state = () => ({
//   xyz: 0
// })

// export const getters = {
//   get(state) {
//     return state.list
//   }
// }

// export const mutations = {
//   add(state, title) {
//     state.list.push(title)
//   }
// }

export * from './store-accessor'
export const strict = false
