const serviceList = [
  {
    Key: 'Stream',
    Name: 'Stream',
    Logo: 'Stream.svg',
    LogoAlt: null,
    Path: '/stream',
    Color: 'stream',
    ColorAlias: 'blue',
    ColorClass: 'blue',
    Slug: 'stream',
    ActionPortalUrl: 'https://tc6.idioma.com/client-portal/estimates/new'
  },
  {
    Key: 'NextDoc',
    Name: 'NextDoc',
    Logo: 'NextDoc.svg',
    LogoAlt: null,
    Path: '/nextdoc',
    Color: 'nextdoc',
    ColorAlias: 'green',
    ColorClass: 'green',
    Slug: 'nextdoc',
    ActionPortalUrl: 'https://tc6.idioma.com/client-portal/nextdoc/new'
  },
  {
    Key: 'Ask',
    Name: 'Ask!',
    Logo: 'Ask.svg',
    LogoAlt: null,
    Path: '/ask',
    Color: 'ask',
    ColorAlias: 'purple',
    ColorClass: 'purple',
    Slug: 'ask',
    ActionPortalUrl: 'https://tc6.idioma.com/client-portal/ask-estimates/new'
  },
  {
    Key: 'ISync',
    Name: 'iSync',
    Logo: 'iSync.svg',
    LogoAlt: null,
    Path: '/isync',
    Color: 'isync',
    ColorAlias: 'cyan',
    ColorClass: 'cyan',
    Slug: 'isync',
    ActionPortalUrl: 'https://tc6.idioma.com/client-portal',
    ActionMoreUrl: 'http://translationmemory.com'
    // ActionContactUrl: '#'
  },
  {
    Key: 'CrossCheck',
    Name: 'CrossCheck',
    Logo: 'CrossCheck.svg',
    LogoAlt: 'CrossCheck-v.svg',
    Path: '/crosscheck',
    Color: 'crosscheck',
    ColorAlias: 'red',
    ColorClass: 'red',
    Slug: 'crosscheck',
    ActionPortalUrl: 'https://tc6.idioma.com/client-portal',
    ActionMoreUrl: 'https://runcrosscheck.com'
  },
  {
    Key: 'Iglossary',
    Name: 'iGlossary',
    Logo: 'iglossary.svg',
    LogoAlt: 'iglossary-v.svg',
    Path: '/iglossary',
    Color: 'iglossary',
    ColorAlias: 'lightGreen',
    ColorClass: 'light-green',
    Slug: 'iglossary',
    ActionPortalUrl: 'https://tc6.idioma.com/client-portal',
    ActionMoreUrl: 'http://translationmemory.com'
    // ActionContactUrl: '#'
  },
  {
    Key: 'IdiomaNmt',
    Name: 'idiomaNMT',
    Logo: 'idiomaNMT.svg',
    LogoAlt: 'idiomaNMT-v.svg',
    Path: '/idioma-nmt',
    Color: 'idiomaNmt',
    ColorAlias: 'lightBlue',
    ColorClass: 'light-blue',
    Slug: 'idioma-nmt',
    // ActionContactUrl: '/contact-us',
    ActionPortalUrl: 'https://tc6.idioma.com/client-portal',
    ActionMoreUrl: '/nmt-plus-pe'
  },
  {
    Key: 'StreamApi',
    Name: 'StreamAPI',
    Logo: 'StreamAPI.svg',
    LogoAlt: null,
    Path: '/stream-api',
    Color: 'streamApi',
    ColorAlias: 'orange',
    ColorClass: 'orange',
    Slug: 'stream-api',
    ActionMoreUrl: 'http://localization.idioma.com/'
  }
  // {
  //   Key: 'TemplateService',
  //   Name: 'Service Template',
  //   Logo: 'CrossCheck.svg',
  //   Path: '/template',
  //   Color: 'crosscheck',
  //   ColorAlias: 'red',
  //   ColorClass: 'red',
  //   Slug: 'template',
  //   ActionPortalUrl: '#'
  // }
]

export default serviceList
