
















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('lazy')

const defaultRule = { required: { allowFalse: false } }

@Component({
  name: 'PrivacyPolicyConsent',
  components: {
    ValidationProvider
  }
})
export default class extends Vue {
  @Prop() value!: boolean
  @Prop({ default: defaultRule }) rules!: object

  // localGeneralConsent: boolean = this.generalConsent

  // handleGeneralConsent(value) {
  //   this.$emit('update:generalConsent', value)
  // }

  get localValue() {
    return this.value
  }

  set localValue(v) {
    this.$emit('input', v)
  }
}
