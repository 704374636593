









































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Logo from '~/components/Logo.vue'
import NewsletterForm from '~/components/Other/NewsletterForm.vue'
import ContactInfo from '~/components/Other/ContactInfo.vue'
import ButtonContactProjectManager from '~/components/Other/ButtonContactProjectManager.vue'
import { appModule } from '~/store'

@Component({
  name: 'TheFooter',
  components: {
    Logo,
    ContactInfo,
    NewsletterForm,
    ButtonContactProjectManager
  }
})
export default class extends Vue {
  get env() {
    return appModule.Env
  }
}
