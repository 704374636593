import Vue from 'vue'
import VuexPersistence from 'vuex-persist'

declare let window: MyWindow

export default ({ store }: any) => {
  // eslint-disable-next-line
  window.onNuxtReady(() => {
    new VuexPersistence({
      storage: localStorage,
      key: 'idioma_services_0_0_1_dev',
      modules: ['AdminModule', 'PreferencesModule']
    }).plugin(store)
  })
}
