






















import { Component, Vue, Watch, Prop } from 'nuxt-property-decorator'
import { Locale, I18nLocale } from '~/models/LocalizationModels'
import { moveToFront } from '~/utils/CommonUtils'

// extends i18n locale
export interface LocaleObject {
  Locale: Locale
}

@Component({
  name: 'SettingLanguageSwitcher',
  components: {}
})
export default class extends Vue {
  // items: Locale[] = []
  value = false

  @Prop({ default: false }) disabled: boolean
  @Prop({ default: true }) icon: boolean
  @Prop({ default: false }) small: boolean
  @Prop() selectStyle: object

  get userLocale() {
    return this.$i18n.locale
  }

  get items() {
    // @ts-ignore
    const locales: Array<Locale> = this.$i18n.locales.map(
      // @ts-ignore
      (l: I18nLocale) => l.Locale
    )
    return locales
  }

  // mounted() {
  //   const locales: Array<Locale> = this.$i18n.locales.map(
  //     // @ts-ignore
  //     (l: I18nLocale) => l.Locale
  //   )
  //   this.items = locales
  // }
}
