import { createId } from '~/lib/NanoId'

export enum NewsletterSubscriptionLabel {
  NewsletterSubscriptionForm = 'NewsletterSubscriptionForm',
  BlogSubscriptionForm = 'BlogSubscriptionForm',
  ContactForm = 'ContactForm'
}

export class NewsletterSubscriptionForm {
  Email: string
  ConsentGeneral: boolean
  UserLanguage: string
  static rules = {
    Email: {
      required: true,
      email: true
    },
    ConsentGeneral: {
      required: {
        allowFalse: false
      }
    },
    UserLanguage: {
      required: true
    }
  }
}

export class NewsletterSubscription {
  Id: string
  CreatedAt: string
  Email: string
  FirstName: string
  LastName: string
  Company: string
  Phone: string
  Label: NewsletterSubscriptionLabel
  Hostname: string
  IsSubscribed: boolean
  SubscribedAt: string
  UnsubscribedAt: string
  ConsentGeneral: boolean
  ConsentAnalyze: boolean
  ConsentNewsletter: boolean
  ConsentTailoredOffers: boolean
  UserLanguage: string

  public constructor(init?: Partial<NewsletterSubscription>) {
    Object.assign(this, init)
  }

  static create(
    formData: NewsletterSubscriptionForm,
    label: NewsletterSubscriptionLabel,
    hostname: string
  ) {
    const id = createId()
    const now = new Date().toISOString()
    const data: NewsletterSubscription = {
      Id: id,
      CreatedAt: now,
      ...formData,
      FirstName: null,
      LastName: null,
      Company: null,
      Phone: null,
      Label: label,
      Hostname: hostname,
      IsSubscribed: true,
      SubscribedAt: now,
      UnsubscribedAt: null,
      ConsentGeneral: true,
      ConsentAnalyze: true,
      ConsentNewsletter: true,
      ConsentTailoredOffers: true
    }
    return new NewsletterSubscription(data)
  }
}
