import Vue from 'vue'
import {
  configure,
  extend,
  setInteractionMode,
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules.umd.js'

extend('required', required)
extend('email', email)

setInteractionMode('lazy')

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default function ({ app }) {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = field // app.i18n.t(`${field}`)
      return app.i18n.t(`_validations.${values._rule_}`, values)
    }
  })
}
