import Vue from 'vue'
import { Path, TranslateResult } from 'vue-i18n'
// import LocalizeMixin from '../mixins/LocalizeMixin'
// Vue.mixin(LocalizeMixin)

Vue.prototype.$tt = function (key: Path, ...values: any): TranslateResult {
  const i18n = this.$i18n
  return i18n._t(
    this.$options.name + '.' + key,
    i18n.locale,
    i18n._getMessages(),
    this,
    ...values
  )
}
