


















































import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import ContactOfficeDetails from './ContactOfficeDetails.vue'
import ButtonContactProjectManager from '~/components/Other/ButtonContactProjectManager.vue'

@Component({
  name: 'ContactInfo',
  components: {
    ContactOfficeDetails,
    ButtonContactProjectManager
  }
})
export default class extends Vue {
  activeTab = 'JP'

  get userLocale() {
    return this.$i18n.locale
  }

  @Watch('userLocale', { immediate: true })
  localeChange() {
    this.setActiveTab()
  }

  get tabs() {
    return ['JP', 'CZ', 'SE']
  }

  setActiveTab() {
    if (this.userLocale === 'cs') this.activeTab = 'CZ'
    else if (this.userLocale === 'sv') this.activeTab = 'SE'
    else this.activeTab = 'JP'
  }

  mounted() {
    // console.log(this.$i18n)
  }
}
