




















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Logo from '~/components/Logo.vue'
import { appModule } from '~/store'
import { getNavItems } from '~/data/ui/navigationLists'
import services from '~/data/services'
import SettingLanguageSwitcher from '~/components/Setting/SettingLanguageSwitcher.vue'

@Component({
  components: {
    Logo,
    SettingLanguageSwitcher
  }
})
export default class extends Vue {
  serviceList = services

  get env() {
    return appModule.Env
  }

  get navItems() {
    return getNavItems(this.$i18n.locale)
  }

  get mobileNav() {
    return appModule.MobileNav
  }

  set mobileNav(val: boolean) {
    appModule.setMobileNav(val)
  }
}
