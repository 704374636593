export const isDev = process.env.MODE === 'dev'
export const isStaging = process.env.MODE === 'staging'
export const isLive = process.env.MODE === 'live'

export const isIdioma = process.env.WEBSITE === 'idioma'
export const isIdiomaService = process.env.WEBSITE === 'idiomaservice'

// nuxt dev
export const isNodeDevelopment = process.env.NODE_ENV === 'development'
// nuxt build/start/generate
export const isNodeProduction = process.env.NODE_ENV === 'production'
