









































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import PrivacyPolicyConsent from '~/components/Other/PrivacyPolicyConsent.vue'
import { NewsletterSubscriptionForm } from '~/models/NewsletterModels'
import * as FirebaseService from '../../services/FirebaseService'

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'
setInteractionMode('lazy')

@Component({
  name: 'NewsletterForm',
  components: {
    PrivacyPolicyConsent,
    ValidationProvider,
    ValidationObserver
  },
  mixins: []
})
export default class extends Vue {
  form: NewsletterSubscriptionForm = {
    Email: '',
    ConsentGeneral: false,
    UserLanguage: ''
  }

  loading = false
  showSuccessAlert = false
  showErrorAlert = false

  get rules() {
    return NewsletterSubscriptionForm.rules
  }

  async submit() {
    this.showSuccessAlert = false
    this.showErrorAlert = false
    // @ts-ignore
    if (await this.$refs.observer.validate()) {
      this.loading = true
      try {
        const result = await this.postData(this.form)
        this.showSuccessAlert = true
        this.resetForm()
        // TODO: Hide form
      } catch (err) {
        console.log(err)
        this.showErrorAlert = true
      } finally {
        this.loading = false
      }
    }
  }

  async postData(data) {
    return await this.$axios.post('/api/newsletter/subscribe', this.form)
  }

  resetForm() {
    // @ts-ignore
    this.$refs.observer.reset()
  }

  mounted() {
    this.form.UserLanguage = this.$i18n.locale
  }
}
