import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface PreferencesState {
  DarkTheme: boolean
}

const initialState: PreferencesState = {
  DarkTheme: false
}

@Module({
  name: 'PreferencesModule',
  namespaced: true,
  stateFactory: true
})
export default class PreferencesModule extends VuexModule {
  DarkTheme: boolean = initialState.DarkTheme

  @Mutation
  setDarkTheme(payload: { DarkTheme: boolean }) {
    this.DarkTheme = payload.DarkTheme
  }

  @Action({ rawError: true })
  toggleDarkTheme() {
    // this.context.commit('setDarkTheme', {
    //   DarkTheme: !this.DarkTheme
    // })
    this.setDarkTheme({ DarkTheme: !this.DarkTheme })
  }
}
