import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { TextItem, TextRevision } from '~/models/AdminTextModels'
import { Dictionary } from '~/models/GeneralModels'

export interface AdminState {
  TextItemCollections: Dictionary<TextItem[]>
  TextRevisions: TextRevision[]
}

const initialState: AdminState = {
  TextItemCollections: {},
  TextRevisions: []
}

@Module({
  name: 'AdminModule',
  namespaced: true,
  stateFactory: true
})
export default class AdminModule extends VuexModule {
  TextItemCollections: Dictionary<TextItem[]> = initialState.TextItemCollections
  TextRevisions: TextRevision[] = initialState.TextRevisions

  @Mutation
  setTextItemCollections(payload: { Language: string; TextItems: TextItem[] }) {
    this.TextItemCollections[payload.Language] = payload.TextItems
  }

  @Mutation
  setTextRevisions(payload: { TextRevisions: TextRevision[] }) {
    this.TextRevisions = payload.TextRevisions
  }

  @Mutation
  addTextRevision(payload: { TextRevision: TextRevision }) {
    this.TextRevisions.push(payload.TextRevision)
  }

  @Mutation
  deleteTextRevision(payload: { Id: string }) {
    this.TextRevisions = this.TextRevisions.filter(
      (item) => item.Id !== payload.Id
    )
  }

  @Mutation
  clearTextRevisions() {
    this.TextRevisions = []
  }
}
