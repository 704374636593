




import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import { interpolateToLocaleLinks } from '~/helpers/LocalizeUtils'

@Component({
  name: 'HtmlParser'
})
export default class extends Vue {
  @Prop() value: string

  content = null
  links = []

  @Watch('value', { immediate: false })
  valueChanged() {
    this.contentUpdated()
  }

  get userLocale() {
    return this.$i18n.locale
  }

  mounted() {
    this.content = interpolateToLocaleLinks(this.value, this.userLocale)
    this.$nextTick(this.addListeners)
  }

  beforeDestroy() {
    this.removeListeners()
  }

  navigate(event) {
    let target = event.target
    let i = 0
    // Go throught 5 parents max to find a tag
    while (
      i < 5 &&
      !(target instanceof HTMLAnchorElement) &&
      target.parentNode
    ) {
      target = target.parentNode
      i++
    }
    // If target is still not a link, ignore
    if (!(target instanceof HTMLAnchorElement)) {
      return
    }
    const href = target.getAttribute('href')
    // Get link target, if local link, navigate with router link
    if (href && href[0] === '/') {
      event.preventDefault()
      this.$router.push(href)
    }
    // else if (this.$ga) {
    // If Google Analytics is activated & is external link
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
    // this.$ga('send', 'event', 'Outbound Link', 'click', target.href)
    // }
  }

  contentUpdated() {
    this.content = interpolateToLocaleLinks(this.value, this.userLocale)
    console.log(this.content)
    this.removeListeners()
    this.$nextTick(() => {
      this.addListeners()
    })
  }

  addListeners() {
    // @ts-ignore
    this.links = this.$el.getElementsByTagName('a')
    for (let i = 0; i < this.links.length; i++) {
      this.links[i].addEventListener('click', this.navigate, false)
    }
  }

  removeListeners() {
    for (let i = 0; i < this.links.length; i++) {
      this.links[i].removeEventListener('click', this.navigate, false)
    }
    this.links = []
  }
}
