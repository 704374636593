export const vueI18n = {"silentTranslationWarn":true}
export const vueI18nLoader = false
export const locales = [{"code":"en","iso":"en-US","file":"en-US.ts","Locale":{"Id":"en-US","Lang":"en","Name":"English","Native":"English","Enabled":true}},{"code":"ja","iso":"ja-JP","file":"ja-JP.ts","Locale":{"Id":"ja-JP","Lang":"ja","Name":"Japanese","Native":"日本語","Enabled":true}},{"code":"sv","iso":"sv-SE","file":"sv-SE.ts","Locale":{"Id":"sv-SE","Lang":"sv","Name":"Swedish","Native":"Svenska","Enabled":true}}]
export const defaultLocale = 'en'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = 'lang/'
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":true,"cookieDomain":null,"cookieKey":"i18n_redirected","alwaysRedirect":false,"fallbackLocale":null}
export const differentDomains = false
export const seo = true
export const baseUrl = ''
export const vuex = false
export const parsePages = false
export const pages = {"impressum":{"en":false}}
export const encodePaths = true
export const beforeLanguageSwitch = (oldLocale, newLocale) => null
export const onLanguageSwitched = (oldLocale, newLocale) => null
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["en","ja","sv"]
