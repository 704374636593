// import { htmlEscape } from 'escape-goat'
const _htmlEscape = (string) =>
  string
    .replace(/&/g, '&amp;') // Must happen first or else it will escape other just-escaped characters.
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')

const _htmlUnescape = (htmlString) =>
  htmlString
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&#0?39;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&') // Must happen last or else it will unescape other characters in the wrong order.

export function htmlEscape(strings, ...values) {
  if (typeof strings === 'string') {
    return _htmlEscape(strings)
  }

  let output = strings[0]
  for (const [index, value] of values.entries()) {
    output = output + _htmlEscape(String(value)) + strings[index + 1]
  }

  return output
}

export function htmlUnescape(strings, ...values) {
  if (typeof strings === 'string') {
    return _htmlUnescape(strings)
  }

  let output = strings[0]
  for (const [index, value] of values.entries()) {
    output = output + _htmlUnescape(String(value)) + strings[index + 1]
  }

  return output
}

export class MissingValueError extends Error {
  constructor(key) {
    super(`Missing a value for the placeholder: ${key}`)
    this.name = 'MissingValueError'
    this.key = key
  }
}

export default function pupa(
  template,
  data,
  { ignoreMissing = false, transform = ({ value }) => value } = {}
) {
  if (typeof template !== 'string') {
    throw new TypeError(
      `Expected a \`string\` in the first argument, got \`${typeof template}\``
    )
  }

  if (typeof data !== 'object') {
    throw new TypeError(
      `Expected an \`object\` or \`Array\` in the second argument, got \`${typeof data}\``
    )
  }

  const replace = (placeholder, key) => {
    let value = data
    for (const property of key.split('.')) {
      value = value ? value[property] : undefined
    }

    const transformedValue = transform({ value, key })
    if (transformedValue === undefined) {
      if (ignoreMissing) {
        return placeholder
      }

      throw new MissingValueError(key)
    }

    return String(transformedValue)
  }

  const composeHtmlEscape = (replacer) => (...args) =>
    htmlEscape(replacer(...args))

  // The regex tries to match either a number inside `{{ }}` or a valid JS identifier or key path.
  const doubleBraceRegex = /{{(\d+|[a-z$_][\w\-$]*?(?:\.[\w\-$]*?)*?)}}/gi

  if (doubleBraceRegex.test(template)) {
    template = template.replace(doubleBraceRegex, composeHtmlEscape(replace))
  }

  const braceRegex = /{(\d+|[a-z$_][\w\-$]*?(?:\.[\w\-$]*?)*?)}/gi

  return template.replace(braceRegex, replace)
}
