<template>
  <v-app>
    <v-content>
      <v-container fill-height class="error-page">
        <v-row>
          <v-col cols="12" class="text-center">
            <div class="error-code font-bold">{{ error.statusCode }}</div>
            <h1 class="mb-10">{{ texts.Title }}</h1>
            <h2 v-if="texts.Lead" class="mb-5">{{ texts.Lead }}</h2>
            <p class="mb-0">{{ texts.Text }}</p>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn :href="localePath('index')" color="primary" depressed>
              {{ $t('Navigation.Home') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="p-10 text-center">
            <v-img
              :src="require(`~/assets/img/error/i/undraw_not_found_60pq.svg`)"
              contain
              class="mt-10 mx-auto"
              max-width="300"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {
    texts() {
      switch (this.error.statusCode) {
        case 404:
          return this.$t(`ErrorPage.${this.error.statusCode}`)
        case 500:
          return this.$t(`ErrorPage.${this.error.statusCode}`)
        default:
          return this.$t(`ErrorPage.500`)
      }
    }
  },
  head() {
    const title = this.texts.Title || '' // TODO
    return {
      title
    }
  }
}
</script>

<style lang="scss" scoped>
.error-page {
  .error-code {
    font-size: 5rem;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.25rem;
  }
}
</style>
