import flatten from 'lodash/flatten'
import orderBy from 'lodash/orderBy'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import { format, addMinutes } from 'date-fns'

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * For object (or array) `obj`, recursively search all keys
 * and generate unique paths for every key in the tree.
 */
export const getUniqueKeyPaths = (obj, prev = ''): Array<string> =>
  flatten(
    Object.entries(obj).map((entry) => {
      const [k, v] = entry
      if (v !== null && typeof v === 'object') {
        const newK = prev ? `${prev}.${k}` : `${k}`
        // Must include the prev and current k before going recursive so we don't lose keys whose values are arrays or objects
        return [newK, ...getUniqueKeyPaths(v, newK)]
      }
      return `${prev}.${k}`
    })
  )

export const stripTrailingSlash = (str: string): string => {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

export function hexToRgba(hex: string, opacity: number = 1): string {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')'
}

export const sortByCreatedDesc = (items) =>
  orderBy(items, [(item) => item.Created], ['desc'])

export const sortById = (items) => orderBy(items, [(item) => item.Id], ['asc'])

function sortByFrequency(array) {
  const frequency = {}
  array.forEach(function (value) {
    frequency[value] = 0
  })
  const uniques = array.filter(function (value) {
    return ++frequency[value] === 1
  })
  return uniques.sort(function (a, b) {
    return frequency[b] - frequency[a]
  })
}

export const moveToFront = <T>(array: Array<T>, item: any): Array<T> => {
  let data = array.slice()
  data = data.filter((i) => i !== item)
  data.unshift(item)
  return data
}

export function traverseObj(obj, f) {
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] === 'object') {
        traverseObj(obj[property], f)
      } else {
        obj[property] = f(obj[property])
      }
    }
  }
  return obj
}

export const flattenObj = (obj = {}) => {
  const result = {}

  const flatten = (collection, prefix = '', suffix = '') => {
    forEach(collection, (value, key) => {
      const path = `${prefix}${key}${suffix}`

      if (isArray(value)) {
        flatten(value, `${path}[`, ']')
      } else if (isPlainObject(value)) {
        flatten(value, `${path}.`)
      } else {
        result[path] = value
      }
    })
  }

  flatten(obj)

  return result
}

export function reverseString(str) {
  return str.split('').reverse().join('')
}

/* ---------------------------------- Date ---------------------------------- */

const formatPatterns = {
  fileName: 'yyyyMMdd_HHmmss'
}
const formatDate = (date: Date, pattern: string) =>
  format(addMinutes(date, date.getTimezoneOffset()), pattern)

const formatToFileName = (date: Date) =>
  formatDate(date, formatPatterns.fileName)

export const nowInFileNameFormat = () => formatToFileName(new Date())
