import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2f2016bc = () => interopDefault(import('../pages/about-idioma.vue' /* webpackChunkName: "pages/about-idioma" */))
const _3ab3d422 = () => interopDefault(import('../pages/ask.vue' /* webpackChunkName: "pages/ask" */))
const _1b3227c9 = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _1d49f38c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _63273b34 = () => interopDefault(import('../pages/blog/archive/_archive.vue' /* webpackChunkName: "pages/blog/archive/_archive" */))
const _2cf96334 = () => interopDefault(import('../pages/blog/tag/_tag.vue' /* webpackChunkName: "pages/blog/tag/_tag" */))
const _1b935244 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _879db85c = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _874ffaa2 = () => interopDefault(import('../pages/crosscheck.vue' /* webpackChunkName: "pages/crosscheck" */))
const _bb047776 = () => interopDefault(import('../pages/data-protection-and-privacy-policy.vue' /* webpackChunkName: "pages/data-protection-and-privacy-policy" */))
const _24d04e3f = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _25a60686 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _d5b78df8 = () => interopDefault(import('../pages/idioma-nmt.vue' /* webpackChunkName: "pages/idioma-nmt" */))
const _16f17920 = () => interopDefault(import('../pages/iglossary.vue' /* webpackChunkName: "pages/iglossary" */))
const _3d1f323a = () => interopDefault(import('../pages/industries.vue' /* webpackChunkName: "pages/industries" */))
const _4fd47a6d = () => interopDefault(import('../pages/isync.vue' /* webpackChunkName: "pages/isync" */))
const _930d70b6 = () => interopDefault(import('../pages/languages-supported.vue' /* webpackChunkName: "pages/languages-supported" */))
const _d476fb0c = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _665fc57d = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _64a92435 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _9289f6e4 = () => interopDefault(import('../pages/nextdoc.vue' /* webpackChunkName: "pages/nextdoc" */))
const _7f682e0e = () => interopDefault(import('../pages/nmt-plus-pe.vue' /* webpackChunkName: "pages/nmt-plus-pe" */))
const _a1767288 = () => interopDefault(import('../pages/our-history.vue' /* webpackChunkName: "pages/our-history" */))
const _6c02aebb = () => interopDefault(import('../pages/our-organization.vue' /* webpackChunkName: "pages/our-organization" */))
const _71f96df9 = () => interopDefault(import('../pages/our-philosophy.vue' /* webpackChunkName: "pages/our-philosophy" */))
const _54e9aec2 = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */))
const _43b9bede = () => interopDefault(import('../pages/project-management.vue' /* webpackChunkName: "pages/project-management" */))
const _6df0b10e = () => interopDefault(import('../pages/resources.vue' /* webpackChunkName: "pages/resources" */))
const _499e6b05 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _168ac0e7 = () => interopDefault(import('../pages/stream.vue' /* webpackChunkName: "pages/stream" */))
const _ce367c98 = () => interopDefault(import('../pages/stream-api.vue' /* webpackChunkName: "pages/stream-api" */))
const _5324729b = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _1e18d12a = () => interopDefault(import('../pages/translation-in-iqube.vue' /* webpackChunkName: "pages/translation-in-iqube" */))
const _394fe48a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _17f06b40 = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _82a9b09a = () => interopDefault(import('../pages/_ui/colors.vue' /* webpackChunkName: "pages/_ui/colors" */))
const _5a7450b2 = () => interopDefault(import('../pages/_ui/icons.vue' /* webpackChunkName: "pages/_ui/icons" */))
const _5c451a72 = () => interopDefault(import('../pages/_ui/typography.vue' /* webpackChunkName: "pages/_ui/typography" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-idioma",
    component: _2f2016bc,
    name: "about-idioma___en"
  }, {
    path: "/ask",
    component: _3ab3d422,
    name: "ask___en"
  }, {
    path: "/blog",
    component: _1b3227c9,
    children: [{
      path: "",
      component: _1d49f38c,
      name: "blog___en"
    }, {
      path: "archive/:archive?",
      component: _63273b34,
      name: "blog-archive-archive___en"
    }, {
      path: "tag/:tag?",
      component: _2cf96334,
      name: "blog-tag-tag___en"
    }, {
      path: ":slug",
      component: _1b935244,
      name: "blog-slug___en"
    }]
  }, {
    path: "/contact-us",
    component: _879db85c,
    name: "contact-us___en"
  }, {
    path: "/crosscheck",
    component: _874ffaa2,
    name: "crosscheck___en"
  }, {
    path: "/data-protection-and-privacy-policy",
    component: _bb047776,
    name: "data-protection-and-privacy-policy___en"
  }, {
    path: "/faq",
    component: _24d04e3f,
    name: "faq___en"
  }, {
    path: "/home",
    component: _25a60686,
    name: "home___en"
  }, {
    path: "/idioma-nmt",
    component: _d5b78df8,
    name: "idioma-nmt___en"
  }, {
    path: "/iglossary",
    component: _16f17920,
    name: "iglossary___en"
  }, {
    path: "/industries",
    component: _3d1f323a,
    name: "industries___en"
  }, {
    path: "/isync",
    component: _4fd47a6d,
    name: "isync___en"
  }, {
    path: "/languages-supported",
    component: _930d70b6,
    name: "languages-supported___en"
  }, {
    path: "/news",
    component: _d476fb0c,
    children: [{
      path: "",
      component: _665fc57d,
      name: "news___en"
    }, {
      path: ":slug",
      component: _64a92435,
      name: "news-slug___en"
    }]
  }, {
    path: "/nextdoc",
    component: _9289f6e4,
    name: "nextdoc___en"
  }, {
    path: "/nmt-plus-pe",
    component: _7f682e0e,
    name: "nmt-plus-pe___en"
  }, {
    path: "/our-history",
    component: _a1767288,
    name: "our-history___en"
  }, {
    path: "/our-organization",
    component: _6c02aebb,
    name: "our-organization___en"
  }, {
    path: "/our-philosophy",
    component: _71f96df9,
    name: "our-philosophy___en"
  }, {
    path: "/password",
    component: _54e9aec2,
    name: "password___en"
  }, {
    path: "/project-management",
    component: _43b9bede,
    name: "project-management___en"
  }, {
    path: "/resources",
    component: _6df0b10e,
    name: "resources___en"
  }, {
    path: "/services",
    component: _499e6b05,
    name: "services___en"
  }, {
    path: "/stream",
    component: _168ac0e7,
    name: "stream___en"
  }, {
    path: "/stream-api",
    component: _ce367c98,
    name: "stream-api___en"
  }, {
    path: "/terms-and-conditions",
    component: _5324729b,
    name: "terms-and-conditions___en"
  }, {
    path: "/translation-in-iqube",
    component: _1e18d12a,
    name: "translation-in-iqube___en"
  }, {
    path: "/ja/",
    component: _394fe48a,
    name: "index___ja"
  }, {
    path: "/ja/about-idioma",
    component: _2f2016bc,
    name: "about-idioma___ja"
  }, {
    path: "/ja/ask",
    component: _3ab3d422,
    name: "ask___ja"
  }, {
    path: "/ja/blog",
    component: _1b3227c9,
    children: [{
      path: "",
      component: _1d49f38c,
      name: "blog___ja"
    }, {
      path: "archive/:archive?",
      component: _63273b34,
      name: "blog-archive-archive___ja"
    }, {
      path: "tag/:tag?",
      component: _2cf96334,
      name: "blog-tag-tag___ja"
    }, {
      path: ":slug",
      component: _1b935244,
      name: "blog-slug___ja"
    }]
  }, {
    path: "/ja/contact-us",
    component: _879db85c,
    name: "contact-us___ja"
  }, {
    path: "/ja/crosscheck",
    component: _874ffaa2,
    name: "crosscheck___ja"
  }, {
    path: "/ja/data-protection-and-privacy-policy",
    component: _bb047776,
    name: "data-protection-and-privacy-policy___ja"
  }, {
    path: "/ja/faq",
    component: _24d04e3f,
    name: "faq___ja"
  }, {
    path: "/ja/home",
    component: _25a60686,
    name: "home___ja"
  }, {
    path: "/ja/idioma-nmt",
    component: _d5b78df8,
    name: "idioma-nmt___ja"
  }, {
    path: "/ja/iglossary",
    component: _16f17920,
    name: "iglossary___ja"
  }, {
    path: "/ja/impressum",
    component: _17f06b40,
    name: "impressum___ja"
  }, {
    path: "/ja/industries",
    component: _3d1f323a,
    name: "industries___ja"
  }, {
    path: "/ja/isync",
    component: _4fd47a6d,
    name: "isync___ja"
  }, {
    path: "/ja/languages-supported",
    component: _930d70b6,
    name: "languages-supported___ja"
  }, {
    path: "/ja/news",
    component: _d476fb0c,
    children: [{
      path: "",
      component: _665fc57d,
      name: "news___ja"
    }, {
      path: ":slug",
      component: _64a92435,
      name: "news-slug___ja"
    }]
  }, {
    path: "/ja/nextdoc",
    component: _9289f6e4,
    name: "nextdoc___ja"
  }, {
    path: "/ja/nmt-plus-pe",
    component: _7f682e0e,
    name: "nmt-plus-pe___ja"
  }, {
    path: "/ja/our-history",
    component: _a1767288,
    name: "our-history___ja"
  }, {
    path: "/ja/our-organization",
    component: _6c02aebb,
    name: "our-organization___ja"
  }, {
    path: "/ja/our-philosophy",
    component: _71f96df9,
    name: "our-philosophy___ja"
  }, {
    path: "/ja/password",
    component: _54e9aec2,
    name: "password___ja"
  }, {
    path: "/ja/project-management",
    component: _43b9bede,
    name: "project-management___ja"
  }, {
    path: "/ja/resources",
    component: _6df0b10e,
    name: "resources___ja"
  }, {
    path: "/ja/services",
    component: _499e6b05,
    name: "services___ja"
  }, {
    path: "/ja/stream",
    component: _168ac0e7,
    name: "stream___ja"
  }, {
    path: "/ja/stream-api",
    component: _ce367c98,
    name: "stream-api___ja"
  }, {
    path: "/ja/terms-and-conditions",
    component: _5324729b,
    name: "terms-and-conditions___ja"
  }, {
    path: "/ja/translation-in-iqube",
    component: _1e18d12a,
    name: "translation-in-iqube___ja"
  }, {
    path: "/sv/",
    component: _394fe48a,
    name: "index___sv"
  }, {
    path: "/sv/about-idioma",
    component: _2f2016bc,
    name: "about-idioma___sv"
  }, {
    path: "/sv/ask",
    component: _3ab3d422,
    name: "ask___sv"
  }, {
    path: "/sv/blog",
    component: _1b3227c9,
    children: [{
      path: "",
      component: _1d49f38c,
      name: "blog___sv"
    }, {
      path: "archive/:archive?",
      component: _63273b34,
      name: "blog-archive-archive___sv"
    }, {
      path: "tag/:tag?",
      component: _2cf96334,
      name: "blog-tag-tag___sv"
    }, {
      path: ":slug",
      component: _1b935244,
      name: "blog-slug___sv"
    }]
  }, {
    path: "/sv/contact-us",
    component: _879db85c,
    name: "contact-us___sv"
  }, {
    path: "/sv/crosscheck",
    component: _874ffaa2,
    name: "crosscheck___sv"
  }, {
    path: "/sv/data-protection-and-privacy-policy",
    component: _bb047776,
    name: "data-protection-and-privacy-policy___sv"
  }, {
    path: "/sv/faq",
    component: _24d04e3f,
    name: "faq___sv"
  }, {
    path: "/sv/home",
    component: _25a60686,
    name: "home___sv"
  }, {
    path: "/sv/idioma-nmt",
    component: _d5b78df8,
    name: "idioma-nmt___sv"
  }, {
    path: "/sv/iglossary",
    component: _16f17920,
    name: "iglossary___sv"
  }, {
    path: "/sv/impressum",
    component: _17f06b40,
    name: "impressum___sv"
  }, {
    path: "/sv/industries",
    component: _3d1f323a,
    name: "industries___sv"
  }, {
    path: "/sv/isync",
    component: _4fd47a6d,
    name: "isync___sv"
  }, {
    path: "/sv/languages-supported",
    component: _930d70b6,
    name: "languages-supported___sv"
  }, {
    path: "/sv/news",
    component: _d476fb0c,
    children: [{
      path: "",
      component: _665fc57d,
      name: "news___sv"
    }, {
      path: ":slug",
      component: _64a92435,
      name: "news-slug___sv"
    }]
  }, {
    path: "/sv/nextdoc",
    component: _9289f6e4,
    name: "nextdoc___sv"
  }, {
    path: "/sv/nmt-plus-pe",
    component: _7f682e0e,
    name: "nmt-plus-pe___sv"
  }, {
    path: "/sv/our-history",
    component: _a1767288,
    name: "our-history___sv"
  }, {
    path: "/sv/our-organization",
    component: _6c02aebb,
    name: "our-organization___sv"
  }, {
    path: "/sv/our-philosophy",
    component: _71f96df9,
    name: "our-philosophy___sv"
  }, {
    path: "/sv/password",
    component: _54e9aec2,
    name: "password___sv"
  }, {
    path: "/sv/project-management",
    component: _43b9bede,
    name: "project-management___sv"
  }, {
    path: "/sv/resources",
    component: _6df0b10e,
    name: "resources___sv"
  }, {
    path: "/sv/services",
    component: _499e6b05,
    name: "services___sv"
  }, {
    path: "/sv/stream",
    component: _168ac0e7,
    name: "stream___sv"
  }, {
    path: "/sv/stream-api",
    component: _ce367c98,
    name: "stream-api___sv"
  }, {
    path: "/sv/terms-and-conditions",
    component: _5324729b,
    name: "terms-and-conditions___sv"
  }, {
    path: "/sv/translation-in-iqube",
    component: _1e18d12a,
    name: "translation-in-iqube___sv"
  }, {
    path: "/ja/:ui/colors",
    component: _82a9b09a,
    name: "ui-colors___ja"
  }, {
    path: "/ja/:ui/icons",
    component: _5a7450b2,
    name: "ui-icons___ja"
  }, {
    path: "/ja/:ui/typography",
    component: _5c451a72,
    name: "ui-typography___ja"
  }, {
    path: "/sv/:ui/colors",
    component: _82a9b09a,
    name: "ui-colors___sv"
  }, {
    path: "/sv/:ui/icons",
    component: _5a7450b2,
    name: "ui-icons___sv"
  }, {
    path: "/sv/:ui/typography",
    component: _5c451a72,
    name: "ui-typography___sv"
  }, {
    path: "/",
    component: _394fe48a,
    name: "index___en"
  }, {
    path: "/:ui/colors",
    component: _82a9b09a,
    name: "ui-colors___en"
  }, {
    path: "/:ui/icons",
    component: _5a7450b2,
    name: "ui-icons___en"
  }, {
    path: "/:ui/typography",
    component: _5c451a72,
    name: "ui-typography___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
