<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" temporary fixed app>
      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          router
          exact
        >
          <v-list-item-action>
            <v-icon small>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar fixed app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <!-- <v-btn @click="" icon>
        <v-icon></v-icon>
      </v-btn> -->
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <SettingLanguageSwitcher
        v-if="!$vuetify.breakpoint.smAndDown"
        class="mx-3"
      />
    </v-app-bar>
    <v-content>
      <nuxt />
    </v-content>
    <!-- <v-footer :fixed="fixed" app>
      <span>&copy; 2019</span>
    </v-footer> -->
  </v-app>
</template>

<script>
import SettingLanguageSwitcher from '~/components/Setting/SettingLanguageSwitcher.vue'

export default {
  components: {
    SettingLanguageSwitcher
  },
  data() {
    return {
      drawer: false,
      items: [
        {
          icon: 'fa-text',
          title: 'Text Corrections',
          to: '/admin/texts'
        },
        {
          icon: 'fa-pen',
          title: 'Text Updates',
          to: '/admin/updates'
        }
      ],

      title: 'Admin'
    }
  }
}
</script>
