import Vue from 'vue'
import { Plugin } from '@nuxt/types'
// import VueGtagPlugin, { setOptions, bootstrap, VueGtag } from 'vue-gtag'
import VueGtag from 'vue-gtag'

import id from '../config/googleAnalytics'
import * as ConfigUtils from '../config/ConfigUtils'

// https://matteo-gabriele.gitbook.io/vue-gtag/plugin-options
// debugger https://chrome.google.com/webstore/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna

// {
//   enabled: true,
//   bootstrap: true,
//   onReady: null,
//   onError: null,
//   onBeforeTrack: null,
//   onAfterTrack: null,
//   customResourceURL: "https://www.googletagmanager.com/gtag/js",
//   customPreconnectOrigin: "https://www.googletagmanager.com",
//   deferScriptLoad: false,
//   pageTrackerTemplate: null,
//   pageTrackerExcludedRoutes: [],
//   pageTrackerEnabled: true,
//   pageTrackerScreenviewEnabled: false,
//   pageTrackerUseFullPath: false,
//   pageTrackerPrependBase: true,
//   pageTrackerSkipSamePath: true,
//   disableScriptLoad: false,
//   appName: null,
//   globalDataLayerName: "dataLayer",
//   globalObjectName: "gtag",
//   defaultGroupName: "default",
//   includes: null,
//   config: {
//     id: null,
//     params: {
//       send_page_view: false,
//     },
//   },
// }

// module "vue/types/vue" {
//   interface Vue {
//     $gtag: VueGtag;
//   }
// }

// declare module '@nuxt/types' {
//   // interface NuxtAppOptions {}
//   interface Context {
//     $gtag: VueGtag
//   }
// }

const gtagPlugin: Plugin = ({ app }) => {
  // console.log('gtagPlugin')
  Vue.use(
    VueGtag,
    {
      // enabled: !ConfigUtils.isDev,
      // enabled: true,
      bootstrap: false,
      // globalObjectName: 'gtag',
      config: {
        // id,
        params: {
          // send_page_view: false // to false if you don't want to send the first page hit on load of your application
        }
      }
    },
    app.router
  )
}

export default gtagPlugin
