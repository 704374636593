












import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component({
  name: 'ButtonContactProjectManager',
  components: {},
  mixins: []
})
export default class extends Vue {
  @Prop({ default: 'primary' }) color: string
}
