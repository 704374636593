import colors from 'vuetify/es5/util/colors'
import { Dictionary } from '~/models/GeneralModels'

interface ParsedThemeItem {
  base: string
  lighten5?: string
  lighten4?: string
  lighten3?: string
  lighten2?: string
  lighten1?: string
  darken1?: string
  darken2?: string
  darken3?: string
  darken4?: string

  [name: string]: string
}

export const serviceColors: Dictionary<ParsedThemeItem> = {
  stream: {
    logo: '#1085cb',
    base: colors.blue.base,
    darken1: colors.blue.darken2,
    darken2: colors.blue.darken4,
    accent2: colors.blue.accent2
  },
  ask: {
    logo: '#722981',
    base: colors.purple.base
  },
  isync: {
    logo: '#029c9e',
    base: colors.cyan.base
  },
  crosscheck: {
    logo: '#c32033',
    base: colors.red.base
  },
  iglossary: {
    base: colors.lightGreen.base
  },
  idiomaNmt: {
    base: colors.lightBlue.base
  },
  streamApi: {
    logo: '#d19f65',
    base: colors.amber.base
  },
  nextdoc: {
    base: colors.green.base
  }
}

export const lightThemeColors = {
  primary: colors.green.base,
  secondary: colors.grey.darken3,
  accent: colors.blue.accent1,
  error: colors.red.darken1,
  info: colors.blue.base,
  success: colors.green.darken1,
  warning: colors.amber.base,
  tertiary: colors.blueGrey.lighten4,
  alternate: <ParsedThemeItem>{
    base: colors.blueGrey.lighten5,
    lighten1: colors.grey.lighten5
  },
  ...serviceColors
}

export default function ({ app }) {
  return {
    lang: {
      t: (key, ...params) => app.i18n.t(key, params)
    },
    icons: {
      iconfont: 'fa'
      // values: {
      //   cancel: 'fas fa-ban',
      //   menu: 'fas fa-ellipsis-v'
      // }
    },
    theme: {
      // disable: true,
      // dark: true,
      options: {
        customProperties: false
        // cspNonce: 'dQw4w9WgXcQ',
        // minifyTheme(css) {
        //   // @ts-ignore
        //   return true // process.env.NODE_ENV === 'production' || true
        //     ? css.replace(/[\r\n|\r|\n]/g, '')
        //     : css
        // }
      },
      themes: {
        light: lightThemeColors,
        dark: {
          primary: colors.blue.darken2,
          secondary: colors.grey.darken3,
          accent: colors.blue.accent1,
          error: colors.red.accent2,
          info: colors.blue.base,
          success: colors.green.base,
          warning: colors.amber.base,
          tertiary: colors.blueGrey.darken1,
          alternate: <ParsedThemeItem>{
            base: colors.grey.darken4,
            lighten1: '#272626' // colors.blueGrey.darken4
            // base: colors.blueGrey.darken4,
            // lighten1: colors.blueGrey.darken3
          }
        }
      }
    }
  }
}

// Default vuetify
// {
//     primary: colors.blue.darken2,
//     secondary: colors.grey.darken3,
//     accent: colors.blue.accent1,
//     error: colors.red.accent2,
//     info: colors.blue.base,
//     success: colors.green.base,
//     warning: colors.amber.base
//   }

// interface ParsedThemeItem {
//     base: string
//     lighten5: string
//     lighten4: string
//     lighten3: string
//     lighten2: string
//     lighten1: string
//     darken1: string
//     darken2: string
//     darken3: string
//     darken4: string

//     [name: string]: string
// }
