import { NavItem } from '~/models/GeneralModels'

const isAvailableForLang = (item: NavItem, lang: string): boolean =>
  !item.Langs || item.Langs.includes(lang)

const filterAvailableForLang = (list: NavItem[], lang: string): NavItem[] => {
  const hasChildren = (item: NavItem): boolean => !!item.Children

  const reducer = (accumulator: NavItem[], currentValue: NavItem) => {
    if (!isAvailableForLang(currentValue, lang)) return accumulator
    if (isAvailableForLang(currentValue, lang) && !hasChildren(currentValue))
      return [...accumulator, currentValue]
    return [
      ...accumulator,
      Object.assign({}, currentValue, {
        Children: filterAvailableForLang(currentValue.Children, lang)
      })
    ]
  }

  return list.reduce(reducer, [])
}

const navItems: NavItem[] = [
  {
    Key: 'Translation',
    Icon: null,
    Path: '/industries',
    Children: [
      {
        Key: 'ProjectManagement',
        Icon: null,
        Path: '/project-management'
      },
      {
        Key: 'NmtPlusPe',
        Icon: null,
        Path: '/nmt-plus-pe'
      },
      {
        Key: 'TranslationInIQube',
        Icon: null,
        Path: '/translation-in-iqube'
      },
      {
        Key: 'TranslationApi',
        Icon: null,
        Path: null,
        Href: 'http://localization.idioma.com/',
        Target: '_blank'
      }
    ]
  },
  {
    Key: 'LanguagesSupported',
    Icon: null,
    Path: '/languages-supported'
  },
  {
    Key: 'AboutIdioma',
    Icon: null,
    Path: '/about-idioma',
    Children: [
      {
        Key: 'OurHistory',
        Icon: null,
        Path: '/our-history'
      },
      {
        Key: 'OurOrganization',
        Icon: null,
        Path: '/our-organization'
      },
      {
        Key: 'OurPhilosophy',
        Icon: null,
        Path: '/our-philosophy'
      },
      {
        Key: 'Impressum',
        Icon: null,
        Path: '/impressum',
        Text: '会社概要',
        Langs: ['ja']
      },
      {
        Key: 'Resources',
        Icon: null,
        Path: '/resources'
      }
    ]
  },
  {
    Key: 'ContactUs',
    Icon: null,
    Path: '/contact-us',
    Children: [
      {
        Key: 'Faq',
        Icon: null,
        Path: '/faq'
      }
    ]
  },
  {
    Key: 'Blog',
    Icon: null,
    Path: '/blog'
  },
  {
    Key: 'News',
    Icon: null,
    Path: '/news'
  }
]

export const getNavItems = (lang: string): NavItem[] => {
  return filterAvailableForLang(navItems, lang)
}
