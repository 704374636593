export abstract class TextUtils {
  public static pluralize(word: string, count: number = 0): string {
    return count !== 1 ? `${word}s` : word
  }

  public static pluralizeNew(word: string, count: number = 0): string {
    return count !== 1 ? `${word}s` : word
  }

  public static parseJsonException(exception: string): string {
    try {
      return JSON.parse(exception).Message
    } catch (err) {
      return exception
    }
  }

  // public static buildUrlQuery = (obj) =>
  //   Object.entries(obj)
  //     .map((pair) => pair.map(encodeURIComponent).join('='))
  //     .join('&')

  // public static buildUrl = (url: string, obj) =>
  //   `${url}${url.includes('?') ? '&' : '?'}${TextUtils.buildUrlQuery(obj)}`

  public static toHeaderCase(str) {
    if (!str) return ''
    return String(str)
      .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
      .replace(/([a-z])([A-Z])/g, (m, a, b) => a + '_' + b.toLowerCase())
      .replace(/[^A-Za-z0-9]+|_+/g, ' ')
      .toLowerCase()
      .replace(/( ?)(\w+)( ?)/g, (m, a, b, c) => {
        return a + b.charAt(0).toUpperCase() + b.slice(1) + c
      })
  }

  public static toPascalCase(str) {
    if (!str) return ''
    return String(str)
      .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '$')
      .replace(/[^A-Za-z0-9]+/g, '$')
      .replace(/([a-z])([A-Z])/g, (m, a, b) => a + '$' + b)
      .toLowerCase()
      .replace(/(\$)(\w?)/g, (m, a, b) => b.toUpperCase())
  }

  public static toCamelCase(str) {
    if (!str) return ''
    return String(str)
      .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
      .replace(/[^A-Za-z0-9]+/g, '$')
      .replace(/([a-z])([A-Z])/g, (m, a, b) => a + '$' + b)
      .toLowerCase()
      .replace(/(\$)(\w)/g, (m, a, b) => b.toUpperCase())
  }

  /*
    Detect presence of HTML elements
    console.log(isHTML("a")); // false
    console.log(isHTML("a < >")); // false
    console.log(isHTML("a </>")); // false
    console.log(isHTML("a </ >")); // false
    console.log(isHTML("<>")); // false
    console.log(isHTML("a <b>")); // true
    */
  public static isHTML = RegExp.prototype.test.bind(/<[a-z][\s\S]*>/i)

  public static search(
    str: string,
    searchStr: string,
    caseSensitive: boolean = true
  ): boolean {
    return str.search(new RegExp(searchStr, caseSensitive ? '' : 'i')) >= 0
  }

  public static getAfter = (str: string, after: string) =>
    str.match(new RegExp(after + '(.*)'))[1]

  public static getBefore = (str: string, before: string) =>
    str.match(new RegExp('(.*)' + before))[1]

  public static UUIDGeneratorBrowser = () =>
    // @ts-ignore
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )

  public static slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }
}

// absolutify
export const interpolateUrlsInHtml = (html: string, prefix: string) => {
  if (typeof html !== 'string') return html
  function replace(str, url) {
    if (typeof url === 'function') return replace.iterate(str, url)
    return str.replace(replace.rx, '$1' + url + '/$4')
  }
  replace.rx = /((href|src|codebase|cite|background|cite|action|profile|formaction|icon|manifest|archive)=["'])(([.]+\/)|(?:\/)|(?=#))(?!\/)/g
  replace.captureRx = /((href|src|codebase|cite|background|cite|action|profile|formaction|icon|manifest|archive)=["'])((([.]+\/)|(?:\/)|(?:#))(?!\/)[a-zA-Z0-9._-]+)/g
  replace.iterate = function (str, iterator) {
    return str.replace(replace.captureRx, function (full, prefix, prop, url) {
      return prefix + iterator(url, prop)
    })
  }
  return replace(html, prefix)
}

// const html =
//   '<p>idioma® provides a wide range of <a href="/services">translation services</a> by professional native linguists following ISO quality standards.</p>'
// const lang = 'ja'
// console.log(interpolateUrlsInHtml(html, `/${lang}`))
