















import { Component, Vue, Watch, mixins } from 'nuxt-property-decorator'
import set from 'lodash/set'
// import TheHeader from '~/components/Layout/TheHeader.vue'
import TheMobileNav from '~/components/Layout/TheMobileNav.vue'
import TheFooter from '~/components/Layout/TheFooter.vue'
// import CookiesConsent from '~/components/Layout/CookiesConsent.vue'
import ThemeMixin from '~/mixins/ThemeMixin'
import CookieConsentMixin from '~/mixins/CookieConsentMixin'
import { initialiseStores } from '~/store/store-accessor'
import { appModule, adminModule } from '~/store'
import {
  ComponentItem,
  TextItem,
  SearchOptions,
  FilterOptions,
  TextRevision
} from '~/models/AdminTextModels'

@Component({
  components: {
    TheHeader: () => import('~/components/Layout/TheHeader.vue'),
    TheFooter,
    TheMobileNav
    // CookiesConsent
  },
  mixins: [ThemeMixin, CookieConsentMixin]
})
export default class extends mixins(CookieConsentMixin) {
  // export default class extends Vue {
  get userLocale() {
    return this.$i18n.locale
  }

  get textRevisions(): TextRevision[] {
    return adminModule.TextRevisions
  }

  get jaFont() {
    return this.$i18n.locale === 'ja'
  }

  get gaId() {
    return appModule.GaId
  }

  @Watch('textRevisions')
  textRevisionsChanged(v) {
    // this.updateMessages()
  }

  created() {}

  updateMessages() {
    // console.log(this.$i18n)
    // console.log(this.textRevisions)
    const m = this.$i18n.messages[this.$i18n.locale]
    this.textRevisions
      .filter((r) => r.Language === this.userLocale)
      .map((r) => {
        // console.log(r.Key, r.ContentNew)
        set(m, r.Key, r.ContentNew)
      })
    // console.log(m)
    this.$i18n.setLocaleMessage(this.userLocale, m)
    // console.log();
    // this.$i18n.setLocaleMessage('en', { lkjlj: 'lkjkj' })
    // this.$i18n.mergeLocaleMessage('en', { lkjlj: 'lkjkj' })
  }

  mounted() {
    // console.log(this.$i18n.locales)
    setTimeout(this.updateMessages, 500)

    this.runCookieConsent()
  }

  beforeMount() {
    initialiseStores(this.$store)
  }
}
