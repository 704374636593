// @ts-nocheck

import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import AppModule from '~/store/AppModule'
import AdminModule from '~/store/AdminModule'
import PreferencesModule from '~/store/PreferencesModule'

export let appModule: AppModule
export let adminModule: AdminModule
export let preferencesModule: PreferencesModule

export function initialiseStores(store: Store<any>): void {
  appModule = getModule(AppModule, store)
  adminModule = getModule(AdminModule, store)
  preferencesModule = getModule(PreferencesModule, store)
}
