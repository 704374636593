import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import { Watch } from 'vue-property-decorator'
import VueGtagPlugin, { setOptions, bootstrap, VueGtag } from 'vue-gtag'
// import id from '../config/googleAnalytics'
import textsEn from '~/lang/en-US/CookieConsent.json'
import textsJa from '~/lang/ja-JP/CookieConsent.json'
import textsSv from '~/lang/sv-SE/CookieConsent.json'
// import pupa from 'pupa'
import pupa from '~/vendor/pupa'
import { appModule } from '~/store'
// const { default: pupa } = await import('pupa')

const createLangOption = (lang) => {
  let texts = textsEn
  if (lang === 'ja') texts = textsJa
  if (lang === 'sv') texts = textsSv

  return {
    consent_modal: {
      title: texts.consent_modal.title,
      description: `${texts.consent_modal.description}<div class="cc-add-links"><button type="button" onclick="cookieConsent.showSettings()" class="cc-link">${texts.consent_modal.cookie_settings_btn}</button><a href="/data-protection-and-privacy-policy" target="_blank">${texts.consent_modal.policy_btn}</a></div>`,
      primary_btn: {
        text: texts.consent_modal.accept_all_btn,
        role: 'accept_all' // 'accept_selected' or 'accept_all'
      },
      secondary_btn: {
        text: texts.consent_modal.reject_all_btn,
        role: 'accept_necessary' // 'settings' or 'accept_necessary'
      }
    },
    settings_modal: {
      title: texts.settings_modal.title,
      save_settings_btn: texts.settings_modal.save_settings_btn,
      accept_all_btn: texts.settings_modal.accept_all_btn,
      // reject_all_btn: 'Reject all',
      close_btn_label: texts.settings_modal.close_btn_label,
      cookie_table_headers: [
        { col1: texts.settings_modal.cookie_table_headers.name },
        { col2: texts.settings_modal.cookie_table_headers.expiration },
        { col3: texts.settings_modal.cookie_table_headers.description },
        { col4: texts.settings_modal.cookie_table_headers.provider }
      ],
      blocks: [
        {
          title: texts.settings_modal.blocks.title,
          description: pupa(`${texts.settings_modal.blocks.description}`, {
            PrivacyPolicyLink: `<a href="/data-protection-and-privacy-policy" target="_blank">${texts.consent_modal.policy_btn}</a>`
          })
        },
        {
          title: texts.settings_modal.blocks.categories.necessary.title,
          description:
            texts.settings_modal.blocks.categories.necessary.description,
          toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true
          },
          cookie_table: [
            {
              col1: 'cc_cookie',
              col2:
                texts.settings_modal.blocks.categories.necessary.cookies
                  .cc_cookie.expiration,
              col3:
                texts.settings_modal.blocks.categories.necessary.cookies
                  .cc_cookie.description,
              col4: 'idioma, s.r.o.'
            },
            {
              col1: 'i18n_redirected',
              col2:
                texts.settings_modal.blocks.categories.necessary.cookies
                  .i18n_redirected.expiration,
              col3:
                texts.settings_modal.blocks.categories.necessary.cookies
                  .i18n_redirected.description,
              col4: 'idioma, s.r.o.'
            },
            {
              col1: 'last_visit_&lt;category&gt;_&lt;lang&gt;',
              col2:
                texts.settings_modal.blocks.categories.necessary.cookies
                  .last_visit.expiration,
              col3:
                texts.settings_modal.blocks.categories.necessary.cookies
                  .last_visit.description,
              col4: 'idioma, s.r.o.'
            }
          ]
        },
        {
          title: texts.settings_modal.blocks.categories.performance.title,
          description:
            texts.settings_modal.blocks.categories.performance.description,
          toggle: {
            value: 'performance',
            enabled: false,
            readonly: false
          },
          cookie_table: [
            {
              col1: '^_ga',
              col2:
                texts.settings_modal.blocks.categories.performance.cookies.ga
                  .expiration,
              col3:
                texts.settings_modal.blocks.categories.performance.cookies.ga
                  .description,
              col4: 'Google, Inc',
              is_regex: true
            },
            {
              col1: '_gid',
              col2:
                texts.settings_modal.blocks.categories.performance.cookies.gid
                  .expiration,
              col3:
                texts.settings_modal.blocks.categories.performance.cookies.gid
                  .description,
              col4: 'Google, Inc'
            },
            {
              col1: '_gat',
              col2:
                texts.settings_modal.blocks.categories.performance.cookies.gat
                  .expiration,
              col3:
                texts.settings_modal.blocks.categories.performance.cookies.gat
                  .description,
              col4: 'Google, Inc'
            }
          ]
        }
      ]
    }
  }
}

@Mixin
export default class extends Vue {
  get userLocale() {
    return this.$i18n.locale
  }

  get gaId() {
    return appModule.GaId
  }

  @Watch('userLocale', { immediate: false })
  onUserLocaleChange(newLocale: string, oldLocale: string) {
    console.log(newLocale)
    if (process.browser) {
      // @ts-ignore
      const cc = window ? window.cookieConsent : undefined
      console.log('consent change lang', newLocale)
      if (cc) cc.updateLanguage(newLocale)
    }
  }

  runCookieConsent() {
    if (this.$isServer) return

    // @ts-ignore
    const cc = window.cookieConsent

    window.dataLayer = window.dataLayer || []

    function gtag(...args) {
      window.dataLayer.push(...args)
    }

    const initAnalyticsGranted = () => {
      console.log('initAnalyticsGranted')
      // const self = this
      setOptions({
        config: { id: this.gaId }
      })
      bootstrap().then(() => {
        // console.log('gtag', gtag)
        // self.$gtag.optIn()
        // gtag('consent', 'default', {
        //   analytics_storage: 'granted',
        //   ad_storage: 'granted'
        // })
      })
      // this.$gtag.optIn()
    }

    const initAnalyticsDenied = () => {
      console.log('initAnalyticsDenied')
      this.$gtag.optOut()
      // gtag('consent', 'default', {
      //   analytics_storage: 'denied',
      //   ad_storage: 'denied'
      // })
      // window['ga-disable-UA-XXX-X'] = true
    }

    const updateAnalyticsGranted = () => {
      console.log('updateAnalyticsGranted')
      // const self = this
      setOptions({
        config: { id: this.gaId }
      })
      bootstrap().then((gtag) => {
        // console.log('gtag', gtag)
        // self.$gtag.optIn()
      })
      // gtag('consent', 'update', {
      //   analytics_storage: 'granted',
      //   ad_storage: 'granted'
      // })
      // this.$gtag.optIn()
    }

    function updateAnalyticsDenied() {
      console.log('updateAnalyticsDenied')
      this.$gtag.optOut()
      // gtag('consent', 'update', {
      //   analytics_storage: 'denied',
      //   ad_storage: 'denied'
      // })
    }

    // console.log('cc', cc)

    cc.run({
      current_lang: this.$i18n.locale || 'en',
      autoclear_cookies: true, // default: false
      // theme_css: '../src/cookieconsent.css',
      cookie_name: 'cc_cookie', // default: 'cc_cookie'
      cookie_expiration: 365, // default: 182
      page_scripts: true, // default: false
      // auto_language: 'document', // default: null; could also be 'browser' or 'document'
      // autorun: true,                           // default: true
      // delay: 0,                                // default: 0
      // force_consent: false,
      // hide_from_bots: false,                   // default: false
      // remove_cookie_tables: false              // default: false
      // cookie_domain: location.hostname,        // default: current domain
      // cookie_path: "/",                        // default: root
      // cookie_same_site: "Lax",
      // use_rfc_cookie: false,                   // default: false
      // revision: 0,                             // default: 0

      gui_options: {
        consent_modal: {
          layout: 'cloud', // box,cloud,bar
          position: 'bottom center', // bottom,middle,top + left,right,center
          transition: 'slide' // zoom,slide
        },
        settings_modal: {
          layout: 'box', // box,bar
          // position: 'left',                // right,left (available only if bar layout selected)
          transition: 'slide' // zoom,slide
        }
      },

      // onFirstAction(user_preferences, cookie) {
      //   console.log('onFirstAction fired ...', user_preferences, cookie)
      //   if (cc.allowedCategory('performance')) {
      //     initAnalyticsGranted()
      //   } else {
      //     initAnalyticsDenied()
      //   }
      // },

      onAccept(cookie) {
        console.log(cc)
        console.log('onAccept fired ...', cookie)
        if (cc.allowedCategory('performance')) {
          initAnalyticsGranted()
        }
        // else {
        //   updateAnalyticsDenied()
        // }
      },

      onChange(cookie, changed_preferences) {
        console.log('onChange fired ...', cookie, changed_preferences)
        if (changed_preferences.includes('performance')) {
          if (cc.allowedCategory('performance')) {
            updateAnalyticsGranted()
          } else {
            updateAnalyticsDenied()
          }
        }
      },

      languages: {
        en: createLangOption('en'),
        ja: createLangOption('ja'),
        sv: createLangOption('sv')
      }
    })

    if (!cc.allowedCategory('performance')) initAnalyticsDenied()
  }
}

// const blocks = [
//   {
//     title: 'Preferences',
//     description:
//       'These cookies enable more functionality for our website visitors.',
//     toggle: {
//       value: 'preferences',
//       enabled: false,
//       readonly: false
//     }
//   },
//   {
//     title: 'Marketing',
//     description:
//       'Marketing cookies track visitors across websites to optimize advertising campaigns.',
//     toggle: {
//       value: 'marketing',
//       enabled: false,
//       readonly: false
//     }
//   },
//   {
//     title: 'Unclassified',
//     description: 'These cookies are in the process of classifying.',
//     toggle: {
//       value: 'unclassified',
//       enabled: false,
//       readonly: false
//     }
//   }
// ]
