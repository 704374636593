



























import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
// import ObfuscatedEmail from '~/components/Other/ObfuscatedEmail.vue'

@Component({
  name: 'ContactOfficeDetails',
  components: {
    ObfuscatedEmail: () => import('~/components/Other/ObfuscatedEmail.vue')
  }
})
export default class extends Vue {
  @Prop() country
}
