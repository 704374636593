






import { Component, Vue, Watch } from 'nuxt-property-decorator'

@Component({
  components: {}
})
export default class extends Vue {}
