import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import { Watch } from 'vue-property-decorator'
import { preferencesModule } from '~/store'

@Mixin
export default class extends Vue {
  get darkEnabled() {
    return preferencesModule.DarkTheme
  }

  @Watch('darkEnabled')
  onDarkChanged(val: boolean) {
    this.setTheme(val)
  }

  setTheme(enabledDark: boolean) {
    if (!this.$vuetify.theme) return
    this.$vuetify.theme.dark = enabledDark
  }

  created() {
    this.setTheme(this.darkEnabled)
  }
}
