import { traverseObj, reverseString } from '../utils/CommonUtils'
import { interpolateUrlsInHtml } from './TextUtils'
import { Locale, I18nLocale } from '~/models/LocalizationModels'
import { get, set } from 'object-path-immutable'
import { Base64 } from 'js-base64'
import { LocaleMessageArray } from 'vue-i18n'

export const toI18nLocaleItems = (locales: Locale[]): I18nLocale[] =>
  locales.map((locale: Locale) => ({
    code: locale.Lang,
    iso: locale.Id,
    file: `${locale.Id}.ts`,
    Locale: locale
  }))

// export const toLocaleSelectItems = (locales: Locale[]): object[] =>
//   locales.map((locale) => ({
//     text: locale.Lang.toUpperCase(),
//     value: locale.Lang
//   }))

export const fakeTranslateMessages = (messages, lang) => {
  const f = (text: string): string => (text !== '' ? `[${lang}]${text}` : '')
  return traverseObj(JSON.parse(JSON.stringify(messages)), f)
}

export const interpolateToLocaleLinks = (html, lang) =>
  lang === 'en' ? html : interpolateUrlsInHtml(html, `/${lang}`)

export const obfuscateEmails = (
  mergedMessages: LocaleMessageArray
): LocaleMessageArray => {
  const COUNTRY_CODES = ['JP', 'SE', 'CZ']
  COUNTRY_CODES.forEach((country) => {
    const path = `ContactInfo.Offices.List.${country}.Email`
    const email = get(mergedMessages, path, '')
    // console.log(Base64.encode('✉'))
    const emailReversed = reverseString(email)
    const encodedReversedEmail = Base64.encode(emailReversed)
    mergedMessages = set(mergedMessages, path, encodedReversedEmail)
  })
  return mergedMessages
}
