// if (!window.crypto) {
//   window.crypto = window.msCrypto
// }
import { nanoid, customAlphabet } from 'nanoid'
// import { nanoid, customAlphabet } from 'nanoid/async'

const customNanoId = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  20
)

export const createId = () => customNanoId()

// export const createIdAsync = async () => await customNanoId()

// export { nanoid }
