import * as ConfigUtils from './ConfigUtils'

const idiomaLiveId = 'UA-34386670-8'
const idiomaServiceLiveId = 'UA-34386670-22'

let id: string
id = 'UA-XXX-X'
// if (ConfigUtils.isIdioma && ConfigUtils.isStaging) id = undefined
// if (ConfigUtils.isIdiomaService && ConfigUtils.isStaging) id = undefined
if (ConfigUtils.isIdioma && ConfigUtils.isLive) id = idiomaLiveId
if (ConfigUtils.isIdiomaService && ConfigUtils.isLive) id = idiomaServiceLiveId

console.log('ggg', id)

export { id }

export default id
