import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import id from '../config/googleAnalytics'
export interface AppState {
  Env: object
  MobileNav: boolean
  GaId: string
}

const initialState: AppState = {
  Env: {},
  MobileNav: false,
  GaId: id
}

@Module({
  name: 'AppModule',
  namespaced: true,
  stateFactory: true
})
export default class AppModule extends VuexModule {
  Env: object = initialState.Env
  MobileNav: boolean = initialState.MobileNav
  GaId: string = initialState.GaId

  @Mutation
  setEnv(env) {
    this.Env = env
  }

  @Mutation
  setMobileNav(val: boolean) {
    this.MobileNav = val
  }

  @Mutation
  toggleMobileNav() {
    this.MobileNav = !this.MobileNav
  }
}
