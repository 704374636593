import { Store } from 'vuex'
import { Context } from '@nuxt/types'
import { RootState } from './types'
import { appModule } from './store-accessor'

export default {
  nuxtServerInit(context) {
    // server: { req: IncomingMessage; app: Vue }
    if (process.server) {
      appModule.setEnv({
        mode: process.env.MODE,
        website: process.env.WEBSITE
        // mode: context.env.mode,
        // website: context.env.website
      })
    }
    // await sampleStore.serverInit(server)
  },
  nuxtClientInit(store: Store<RootState>, context: Context) {
    // await console.log('nuxtClientInit', store, context)
    // appModule.setEnv({
    //   mode: context.env.mode,
    //   website: context.env.website
    // })
  }
  // runExampleMutations
}
