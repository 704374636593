import { Plugin } from '@nuxt/types'
import 'vanilla-cookieconsent/src/cookieconsent.js'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import '~/assets/styles/cookieconsent-custom.css'
import VueGtagPlugin, { setOptions, bootstrap, VueGtag } from 'vue-gtag'

const cookieConsentPlugin: Plugin = (ctx, inject) => {
  // @ts-ignore
  const cc = window.initCookieConsent()
  console.log(cc)

  // inject('cookieConsent', cc)
  window.cookieConsent = cc
}

export default cookieConsentPlugin
